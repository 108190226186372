import { invoke } from '../../scripts/shared/easyfy.decorators';
import { viewPort } from '../../scripts/shared/easyfy.utils';
import { slider } from '../../scripts/shared/slider';
@invoke
export class ProductList {
  constructor() {}

  onInit() {
    this.checkoutUpSellProductlistFix();
    slider('[data-keenslider]', { navigation: viewPort().desktop });
  }

  checkoutUpSellProductlistFix() {
    const upsellContainer = document.querySelector('[data-container="upsell"]');
    if (upsellContainer != null) {
      if (upsellContainer.getAttribute('data-has-products') === 'True') {
        document.querySelector('[data-container="checkout-delivery-payment"]')?.classList.add('has-upsell');
      }
    }
  }
}
